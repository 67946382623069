import { OutboundLink } from "gatsby-plugin-google-gtag"
import { Odd } from "src/components/Grid"
import FacebookIcon from "src/svg/facebook.svg"
import InstagramIcon from "src/svg/instagram.svg"
import LinkedinIcon from "src/svg/linkedin.svg"
import MailIcon from "src/svg/mail.svg"
import PhoneIcon from "src/svg/phone.svg"
import TwitterIcon from "src/svg/twitter.svg"
import WorldIcon from "src/svg/world.svg"
import YoutubeIcon from "src/svg/youtube.svg"
import styled, { DefaultTheme, ThemeProps } from "styled-components"

const Container = styled(Odd)`
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin: 0 10px;
    fill: ${(prop: ThemeProps<DefaultTheme>) => prop.theme.primaryColor};
    height: 20px;
    width: 22px;
  }
`

interface Props {
  data: Association
}

const Social = ({ data }: Props) => {
  const { facebook_url, twitter_url, instagram_url, youtube_url, linkedin_url, website_url, email, phone } = data

  return (
    <Container>
      {phone && (
        <OutboundLink href={`tel:${phone}`}>
          <PhoneIcon />
        </OutboundLink>
      )}
      {email && (
        <OutboundLink href={`mailto:${email}`}>
          <MailIcon />
        </OutboundLink>
      )}
      {website_url && (
        <OutboundLink href={website_url} target="_blank" rel="noopener">
          <WorldIcon />
        </OutboundLink>
      )}
      {facebook_url && (
        <OutboundLink href={facebook_url} target="_blank" rel="noopener">
          <FacebookIcon />
        </OutboundLink>
      )}
      {instagram_url && (
        <OutboundLink href={instagram_url} target="_blank" rel="noopener">
          <InstagramIcon />
        </OutboundLink>
      )}
      {twitter_url && (
        <OutboundLink href={twitter_url} target="_blank" rel="noopener">
          <TwitterIcon />
        </OutboundLink>
      )}
      {youtube_url && (
        <OutboundLink href={youtube_url} target="_blank" rel="noopener">
          <YoutubeIcon />
        </OutboundLink>
      )}
      {linkedin_url && (
        <OutboundLink href={linkedin_url} target="_blank" rel="noopener">
          <LinkedinIcon />
        </OutboundLink>
      )}
    </Container>
  )
}

export default Social
