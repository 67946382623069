import { Link } from "gatsby"
import { Fragment } from "react"
import { clamp } from "src/components/Clamp"
import { COLORS } from "src/constants"
import Chevron from "src/svg/chevron.svg"
import styled from "styled-components"

const Container = styled.div`
  ${clamp}
  svg {
    vertical-align: -1px;
  }
`
const Entry = styled(Link)<{ $active?: boolean }>`
  color: ${({ $active, theme }) => ($active ? theme.primaryHighlightedColor : theme.primaryColor)};
`
const ChevronIcon = styled(Chevron)`
  margin: 0 6px;
  fill: ${COLORS.greyDark};
`

interface Props {
  links: string[][]
}

const Breadcrumb: React.FC<Props> = ({ links }) => {
  return (
    <Container $lines={1}>
      <Entry to="/">Accueil</Entry>
      <ChevronIcon width={7} height={12} />
      {links.map((link, index) => {
        return (
          <Fragment key={index}>
            <Entry $active={index === links.length - 1} to={link[1]}>
              {link[0]}
            </Entry>
            {index < links.length - 1 && <ChevronIcon width={7} height={12} />}
          </Fragment>
        )
      })}
    </Container>
  )
}

export default Breadcrumb
