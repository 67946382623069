import { useRef } from "react"

/**
 * Use a ref that update every render.
 * Used to avoid unecessary re-renders.
 */

export const useMutatingRef = <T>(value: T): React.MutableRefObject<T> => {
  const ref = useRef(value)
  ref.current = value
  return ref
}
