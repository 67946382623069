import { Link } from "gatsby"
import { ButtonLink, IconButton } from "src/components/Button"
import { clamp } from "src/components/Clamp"
import { COLORS, LAYOUT, SIZES } from "src/constants"
import { getActivityPath, getSearchPath } from "src/helpers/slug"
import { capitalize, formatAge, formatSchedule } from "src/helpers/text"
import AgeIcon from "src/svg/age.svg"
import AmountIcon from "src/svg/amount.svg"
import CalendarIcon from "src/svg/calendar.svg"
import ChevronIcon from "src/svg/chevron.svg"
import UserIcon from "src/svg/user.svg"
import styled, { DefaultTheme, ThemeProps } from "styled-components"

const Container = styled.article`
  min-width: 280px;
  padding: 15px 20px 50px;
  border-radius: 3px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  background-color: ${COLORS.white};
  position: relative;
  height: 100%;

  @media (min-width: ${LAYOUT.width + 1}px) {
    min-height: 370px;
  }
  h1,
  h2,
  h3 {
    color: ${(prop: ThemeProps<DefaultTheme>) => prop.theme.primaryColor};
  }
`
const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  a {
    margin: 0 8px 8px 0;
  }
  margin: 12px 0 -4px;
`
const Description = styled.p`
  ${clamp}
`
const Data = styled.div`
  display: flex;
  margin: 10px 0;

  svg {
    width: 24px;
    flex: 0 0 24px;
    fill: ${(prop: ThemeProps<DefaultTheme>) => prop.theme.primaryColor};
  }
  p {
    margin: 5px 0 0 10px;
    font-size: ${SIZES.small}px;
    white-space: pre-line;
  }
`
const Bottom = styled.div`
  position: absolute;
  bottom: 15px;
  right: 15px;
`

interface Props {
  activity: Activity
}

const ActivityCard: React.FC<Props> = ({ activity }) => {
  const path = getActivityPath(activity)
  const { title, tags, description_text, trainer_name, pricing_text, activity_schedules, age_min, age_max } = activity

  const age = formatAge(age_min, age_max)

  return (
    <Container>
      <Link to={path}>
        <h3>{title}</h3>
      </Link>
      <Tags>
        {tags.map((tag) => (
          <ButtonLink key={tag.id} $empty to={getSearchPath({ tagIds: [tag.id] })} $size="small">
            {capitalize(tag.name)}
          </ButtonLink>
        ))}
      </Tags>
      <Description $lines={5}>{description_text}</Description>

      {trainer_name && (
        <Data>
          <UserIcon />
          <p>{trainer_name}</p>
        </Data>
      )}
      {pricing_text && (
        <Data>
          <AmountIcon />
          <p>{pricing_text}</p>
        </Data>
      )}
      {activity_schedules?.length > 0 && (
        <Data>
          <CalendarIcon />
          <div>
            {activity_schedules.map((schedule) => (
              <p key={schedule.id}>{formatSchedule(schedule)}</p>
            ))}
          </div>
        </Data>
      )}
      {age && (
        <Data>
          <AgeIcon />
          <p>{age}</p>
        </Data>
      )}
      <Bottom>
        <IconButton to={path}>
          <ChevronIcon />
        </IconButton>
      </Bottom>
    </Container>
  )
}

export default ActivityCard
