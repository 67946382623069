import { Link } from "gatsby"
import { ButtonLink, IconButton } from "src/components/Button"
import { clamp } from "src/components/Clamp"
import { COLORS, LAYOUT, SIZES } from "src/constants"
import { AssociationFilterType } from "src/constants/filters"
import { getMissionPath, getSearchPath } from "src/helpers/slug"
import { capitalize } from "src/helpers/text"
import ChevronIcon from "src/svg/chevron.svg"
import ClockIcon from "src/svg/clock.svg"
import { prettyMissionDates, prettyMissionRecurrence } from "src/templates/mission"
import styled, { DefaultTheme, ThemeProps } from "styled-components"

const Container = styled.article`
  min-width: 280px;
  padding: 15px 20px 50px;
  border-radius: 3px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  background-color: ${COLORS.white};
  position: relative;
  height: 100%;

  @media (min-width: ${LAYOUT.width + 1}px) {
    min-height: 370px;
  }
  h1,
  h2,
  h3 {
    color: ${(prop: ThemeProps<DefaultTheme>) => prop.theme.primaryColor};
  }
`
const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  a {
    margin: 0 8px 8px 0;
  }
  margin: 12px 0 -4px;
`
const Description = styled.p`
  ${clamp}
`
const Data = styled.div`
  display: flex;
  margin: 10px 0;

  svg {
    width: 24px;
    flex: 0 0 24px;
  }
  p {
    margin: 5px 0 0 10px;
    font-size: ${SIZES.small}px;
    white-space: pre-line;
  }
`
const Bottom = styled.div`
  position: absolute;
  bottom: 15px;
  right: 15px;
`

export const TagButtonLink = styled(ButtonLink)`
  max-width: 260px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

interface Props {
  mission: Mission
}

export const MissionCard: React.FC<Props> = ({ mission }) => {
  const path = getMissionPath(mission)
  const { title, causes, skills, description_text, recurrence, start_date, end_date } = mission

  return (
    <Container>
      <Link to={path}>
        <h3>{title}</h3>
      </Link>
      <Tags>
        {causes.map((tag) => (
          <TagButtonLink
            key={tag.id}
            $empty
            to={getSearchPath({ type: AssociationFilterType.Mission, missionCauseIds: [tag.id] })}
            $size="small"
          >
            {capitalize(tag.name)}
          </TagButtonLink>
        ))}
      </Tags>
      <Tags>
        {skills.map((tag) => (
          <TagButtonLink
            key={tag.id}
            $empty
            to={getSearchPath({ type: AssociationFilterType.Mission, missionSkillIds: [tag.id] })}
            $size="small"
          >
            {capitalize(tag.name)}
          </TagButtonLink>
        ))}
      </Tags>
      <Description $lines={5}>{description_text}</Description>
      {recurrence && (
        <Data>
          <ClockIcon />
          <div>
            <p>{prettyMissionRecurrence(recurrence)}</p>
            <p>{prettyMissionDates(start_date, end_date)}</p>
          </div>
        </Data>
      )}
      <Bottom>
        <IconButton to={path}>
          <ChevronIcon />
        </IconButton>
      </Bottom>
    </Container>
  )
}
