import { Item, Subtitle } from "src/components/Grid"
import { trimmer } from "src/helpers/text"
import PhoneIcon from "src/svg/phone.svg"

const formatName = (contact: Contact) => {
  const infos: string[] = []

  const name = trimmer(contact, ["civility", "firstname", "lastname"]).join(" ")
  if (name) {
    infos.push(name)
  }

  const title = (contact.title || "").trim()
  if (title) {
    infos.push(title)
  }

  return infos.join(", ")
}

const formatPhone = (phone: string) => phone.replace("+33", "0").replace(/(\d\d(?!$))/g, "$1 ")

interface Props {
  contacts: Contact[]
}

const Contacts = ({ contacts }: Props) => {
  return (
    <>
      <Subtitle>Contacts</Subtitle>
      <ul>
        {contacts.map((contact) => {
          const { id, phone, email } = contact

          return (
            <Item key={id}>
              <div>{formatName(contact)}</div>
              {phone && (
                <div>
                  <a href={`tel:${phone}`}>
                    <PhoneIcon /> {formatPhone(phone)}
                  </a>
                </div>
              )}
              {email && (
                <div>
                  <a href={`mailto:${email}`}>{email}</a>
                </div>
              )}
            </Item>
          )
        })}
      </ul>
    </>
  )
}

export default Contacts
