import { ButtonUrl } from "src/components/Button"
import styled from "styled-components"

export const CustomButton = ({ button: { url, title } }: { button: CustomButton }) => {
  return (
    <ButtonUrl href={url} target="_blank">
      {title}
    </ButtonUrl>
  )
}

const CustomButtonBlockRoot = styled.div`
  margin: 20px 0;
  a + a {
    margin-left: 16px;
  }
`
export const CustomButtonBlock = ({ buttons }: { buttons: CustomButton[] }) => {
  return (
    <CustomButtonBlockRoot>
      {buttons.map((button, index) => (
        <CustomButton key={index} button={button} />
      ))}
    </CustomButtonBlockRoot>
  )
}
