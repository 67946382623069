import { gql } from "@apollo/client"
import { graphql } from "gatsby"
import React, { Fragment } from "react"
import Breadcrumb from "src/components/Breadcrumb"
import { ButtonLink } from "src/components/Button"
import Contacts from "src/components/Contacts"
import { CustomButtonBlock } from "src/components/CustomButton"
import Form from "src/components/Form"
import { Section } from "src/components/Grid"
import Image from "src/components/Image"
import Social from "src/components/Social"
import { DOMAIN_ID, LAYOUT } from "src/constants"
import { MissionRecurrenceType } from "src/constants/filters"
import { useClientQuery } from "src/helpers/apollo"
import { ActivityFields, AssociationFields, MissionFields } from "src/helpers/fragments"
import { prettyFrenchDate } from "src/helpers/prettyDate"
import { getParam } from "src/helpers/search"
import { getAssociationPath, getMissionPath, getSearchPath } from "src/helpers/slug"
import { capitalize } from "src/helpers/text"
import { useAggregatedAddresses } from "src/helpers/useAggregatedAddresses"
import Layout from "src/layout"
import RecurrenceIcon from "src/svg/clock.svg"
import PlaceIcon from "src/svg/place.svg"
import { Description, Head, Header, Infos, Logo, White } from "src/templates/association"
import styled, { DefaultTheme } from "styled-components"

const Subtitle = styled.h2`
  margin-top: 30px;
`
const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  a {
    margin: 0 8px 8px 0;
  }
`
const DataContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  & > div {
    width: 50%;
    min-width: ${(LAYOUT.width - 40) / 2}px;
  }
`
const Item = styled.div`
  display: flex;
  margin: 10px 0;
  svg {
    width: 24px;
  }
  p {
    margin: 4px 6px;
    white-space: pre-line;
  }
`
const ContactBlock = styled.div`
  margin: 20px 0;
`

const GET_MISSION = gql`
  query($domainId: ID!, $id: ID!) {
    Mission(id: $id, domain: $domainId) {
      ...MissionFields
      association {
        ...AssociationFields
      }
    }
  }
  ${ActivityFields}
  ${AssociationFields}
  ${MissionFields}
`

interface Data {
  assolib: {
    Mission: Mission
    Domain: Domain
  }
}

interface PageContext {
  id?: ID
  theme: DefaultTheme
  domain: Domain
}

interface DynamicResults {
  Mission: Mission
}

const MissionTemplate: GatsbyPage<Data, PageContext> = ({ data: staticData, location, pageContext }) => {
  const forceDynamic = pageContext.id && getParam(location, "dynamic")

  const dynamic = useClientQuery<DynamicResults>(
    GET_MISSION,
    forceDynamic
      ? {
          id: pageContext.id,
          domainId: DOMAIN_ID,
        }
      : null
  )
  const domain = pageContext.domain

  const misson = dynamic.data ? dynamic.data.Mission : staticData.assolib.Mission

  const {
    id,
    title: missionTitle,
    addresses,
    causes,
    skills,
    description_text,
    association,
    recurrence,
    start_date,
    end_date,
    customButtons,
  } = misson

  const tagName = capitalize(association.main_tag.name)
  const links = [
    [tagName, getSearchPath({ tagIds: [association.main_tag.id] })],
    [association.name, getAssociationPath(association)],
    [missionTitle, getMissionPath(misson)],
  ]

  const addressList = addresses?.length ? addresses : association.addresses
  const aggregatedAddresses = useAggregatedAddresses(addressList)

  return (
    <Layout
      title={association.name}
      domain={domain}
      description={association.description_text}
      theme={pageContext.theme}
    >
      <Header>
        {association.header_url && <Image node={association.header_image} src={association.header_url} alt="" />}
      </Header>
      <Infos>
        <Section>
          <Head>
            {association.logo_url && (
              <Logo>
                <Image node={association.logo_image} src={association.logo_url} alt="" />
              </Logo>
            )}
            <h1>{association.name}</h1>
          </Head>
          <Breadcrumb links={links} />
          <Subtitle>{missionTitle}</Subtitle>
          <Tags>
            {causes.map((tag) => (
              <ButtonLink key={tag.id} $empty to={getSearchPath({ tagIds: [tag.id] })} $size="small">
                {capitalize(tag.name)}
              </ButtonLink>
            ))}
          </Tags>
          <Tags>
            {skills.map((tag) => (
              <ButtonLink key={tag.id} $empty to={getSearchPath({ tagIds: [tag.id] })} $size="small">
                {capitalize(tag.name)}
              </ButtonLink>
            ))}
          </Tags>
          <Description>{description_text}</Description>
          <DataContainer>
            <div>
              {aggregatedAddresses.length > 0 && (
                <Item>
                  <PlaceIcon />
                  <p>
                    {aggregatedAddresses.map((address) => (
                      <Fragment key={address.id}>
                        {address.complements.length > 0 && <span>{address.complements.join(", ")}, </span>}
                        {address.full_address}
                        <br />
                      </Fragment>
                    ))}
                  </p>
                </Item>
              )}
              {recurrence && (
                <Item>
                  <RecurrenceIcon />
                  <p>
                    {prettyMissionRecurrence(recurrence)}
                    {prettyMissionDates(start_date, end_date)}
                  </p>
                </Item>
              )}
            </div>
          </DataContainer>
          {association.contacts.length > 0 && (
            <ContactBlock>
              <Contacts contacts={association.contacts} />
            </ContactBlock>
          )}
          {customButtons && customButtons.length > 0 && <CustomButtonBlock buttons={customButtons} />}
        </Section>
      </Infos>
      <Social data={association} />
      <White>
        <Section>
          <h1>Je souhaite plus d’information</h1>
          <Form type="MISSION_SUBSCRIPTION" mission={id} />
        </Section>
      </White>
    </Layout>
  )
}

export const prettyMissionRecurrence = (recurrence?: MissionRecurrenceType): string | null => {
  switch (recurrence) {
    case MissionRecurrenceType.Punctual:
      return "Mission ponctuelle"
    case MissionRecurrenceType.Recurrent:
      return "Mission permanente"
    default:
      return null
  }
}

export const prettyMissionDates = (startDate: string | null, endDate: string | null): string | null => {
  if (startDate && endDate) {
    return ` du ${prettyFrenchDate(new Date(startDate))} au ${prettyFrenchDate(new Date(endDate))}`
  }
  if (startDate) {
    return ` à partir du ${prettyFrenchDate(new Date(startDate))}`
  }
  if (endDate) {
    return ` jusqu'au ${prettyFrenchDate(new Date(endDate))}`
  }
  return null
}

export default MissionTemplate

export const query = graphql`
  query($id: ID!) {
    assolib {
      Mission(id: $id) {
        ...MissionFields
        association {
          ...AssociationFields
        }
      }
    }
  }
`
