import { groupBy, uniq } from "lodash"
import { useMemo } from "react"

interface AggregatedAddress extends Address {
  complements: string[]
}

export const useAggregatedAddresses = (addresses: Address[]): AggregatedAddress[] => {
  return useMemo(
    () =>
      Object.values(groupBy(addresses, (address) => address.full_address)).map((addressesWithSameFullAddress) => {
        const address = addressesWithSameFullAddress[0]
        const complements = uniq(
          addressesWithSameFullAddress
            .map((a) => a.complement)
            .filter(Boolean)
            .sort()
        )
        return { ...address, complements }
      }),
    [addresses]
  )
}
