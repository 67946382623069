import { gql } from "@apollo/client"
import { graphql, PageProps } from "gatsby"
import React, { Fragment, useCallback, useEffect, useRef, useState } from "react"
import ActivityCard from "src/cards/ActivityCard"
import AssociationCard from "src/cards/AssociationCard"
import { MissionCard } from "src/cards/MissionCard"
import Breadcrumb from "src/components/Breadcrumb"
import { Button } from "src/components/Button"
import Contacts from "src/components/Contacts"
import Form from "src/components/Form"
import { Block, Grid, Item, Odd, Section, Subtitle } from "src/components/Grid"
import Image from "src/components/Image"
import Loader from "src/components/Loader"
import Social from "src/components/Social"
import { COLORS, DOMAIN_ID, LAYOUT, SIZES } from "src/constants"
import { AssociationFilterType } from "src/constants/filters"
import { DataWithMeta, useClientQuery } from "src/helpers/apollo"
import { ActivityFields, AssociationFields, MissionFields } from "src/helpers/fragments"
import { getParam, getParams } from "src/helpers/search"
import { getAssociationPath, getSearchPath } from "src/helpers/slug"
import { capitalize } from "src/helpers/text"
import { useAggregatedAddresses } from "src/helpers/useAggregatedAddresses"
import { useMutatingRef } from "src/hooks/useMutatingRef"
import Layout from "src/layout"
import styled, { DefaultTheme, ThemeProps } from "styled-components"

const GET_ASSOCIATION_DYNAMIC = gql`
  query($id: ID!, $domainId: ID!) {
    Association(id: $id, domain: $domainId) {
      ...AssociationFields
      addresses {
        id
        street
        city
        zipcode
        full_address
      }
      activities {
        ...ActivityFields
      }
      missions {
        ...MissionFields
      }
    }
  }
  ${AssociationFields}
  ${ActivityFields}
  ${MissionFields}
`

const SEARCH_QUERY = gql`
  query(
    $activities: [ID!]
    $missions: [ID!]
    $domainId: ID!
    $tags: [ID!]
    $ages: [ID!]
    $days: [DaysOfWeek!]
    $schedules: [ID!]
    $association: ID!
    $mission_causes: [ID!]
    $mission_skills: [ID!]
    $missionRecurrences: [MissionRecurrence!]
    $exact: Boolean
  ) {
    allActivities(
      ids: $activities
      domain: $domainId
      tags: $tags
      age_sub_groups: $ages
      days: $days
      schedule_groups: $schedules
      association: $association
      absolute: $exact
      sortField: "title"
      sortOrder: ASC
    ) {
      ...ActivityFields
    }
    allMissions(
      ids: $missions
      domain: $domainId
      tags: $tags
      recurrences: $missionRecurrences
      causes: $mission_causes
      skills: $mission_skills
      association: $association
      absolute: $exact
      sortField: "title"
      sortOrder: ASC
    ) {
      ...MissionFields
    }
  }
  ${ActivityFields}
  ${MissionFields}
`

export const Header = styled.div`
  background-image: ${(prop: ThemeProps<DefaultTheme>) => prop.theme.headerBgUrl};
  background-size: cover;
  height: 240px;
  overflow: hidden;

  .gatsby-image-wrapper {
    height: 100%;
  }

  img {
    width: 100%;
  }
`
export const Infos = styled.div`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  padding-bottom: 10px;
`
export const Head = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  margin: -60px 0 15px;
  min-height: 120px;
  h1 {
    margin-bottom: 5px;
  }
`
export const Logo = styled.div`
  width: 120px;
  height: 120px;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.bright};
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
`
export const Description = styled.p`
  font-size: ${SIZES.medium}px;
  margin: 20px 0;
  white-space: pre-line;
`
export const Grey = styled(Odd)`
  padding: 20px 0;
`
export const White = styled.div`
  padding: 20px 0;
`
export const Center = styled(Section)`
  padding: 40px 20px;
  text-align: center;
  p {
    font-size: ${SIZES.medium}px;
    line-height: 22px;
  }
`
const ContactBlock = styled(Block)`
  @media (max-width: ${LAYOUT.width - 1}px) {
    border-bottom: 1px solid ${COLORS.bright};
    margin-bottom: 0px;
  }
`
const MoreButton = styled(Button)`
  margin: 20px auto;
  display: block;
`

const SubEntityTabTitle = styled.div`
  display: inline-block;
  cursor: pointer;
  color: lightgrey;
  &.selected {
    color: black;
  }
  & + & {
    margin-left: 16px;
  }
`

const sortActivities = (left: Activity, right: Activity) =>
  left.title.toLowerCase() > right.title.toLowerCase() ? 1 : -1

const sortMissions = (left: Mission, right: Mission) => (left.title.toLowerCase() > right.title.toLowerCase() ? 1 : -1)

const shuffleArray = (array: any[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
  return array
}

interface Data {
  assolib: {
    Association: Association
    allAssociations: Association[]
  }
}

interface PageContext {
  id?: ID
  theme: DefaultTheme
  domain: Domain
}

interface SearchResults extends DataWithMeta {
  allActivities: Activity[]
  allMissions: Mission[]
}

interface DynamicResults {
  Association: Association
}

const AssociationTemplate: GatsbyPage<Data, PageContext> = ({ data: staticData, location, pageContext }) => {
  const forceDynamic = pageContext.id && getParam(location, "dynamic")

  const dynamic = useClientQuery<DynamicResults>(
    GET_ASSOCIATION_DYNAMIC,
    forceDynamic
      ? {
          id: pageContext.id,
          domainId: DOMAIN_ID,
        }
      : null
  )

  const data = dynamic.data
    ? {
        Association: dynamic.data.Association,
        allAssociations: [dynamic.data.Association],
      }
    : staticData.assolib

  const domain = pageContext.domain

  const {
    id,
    main_tag,
    name,
    description_text,
    header_url,
    header_image,
    logo_url,
    logo_image,
    contacts,
    addresses,
  } = data.Association

  const related = data.allAssociations.filter((asso) => asso.absolute && asso.id !== id)

  const [open, setOpen] = useState(false)
  const toggleOpen = () => setOpen(!open)

  const aggregatedAddresses = useAggregatedAddresses(addresses)

  const tagName = capitalize(main_tag.name)
  const links = [
    [tagName, getSearchPath({ tagIds: [main_tag.id] })],
    [name, getAssociationPath(data.Association)],
  ]

  return (
    <Layout title={name} domain={domain} description={description_text} theme={pageContext.theme}>
      <Header>{header_url && <Image node={header_image} src={header_url} alt="" />}</Header>
      <Infos>
        <Section>
          <Head>
            {logo_url && (
              <Logo>
                <Image node={logo_image} src={logo_url} alt="" />
              </Logo>
            )}
            <h1>{name}</h1>
          </Head>
          <Breadcrumb links={links} />
          <Description>{description_text}</Description>
          <Grid>
            {contacts.length > 0 && (
              <ContactBlock $col={2}>
                <Contacts contacts={contacts} />
              </ContactBlock>
            )}
            {aggregatedAddresses.length > 0 && (
              <Block $col={2}>
                <Subtitle>{name}</Subtitle>
                <ul>
                  {aggregatedAddresses.map((address) => (
                    <Fragment key={address.id}>
                      <Item>
                        {address.complements.length > 0 && (
                          <>
                            {address.complements.join(", ")}
                            <br />
                          </>
                        )}
                        {address.street}
                        <br />
                        {address.zipcode} {address.city}
                      </Item>
                    </Fragment>
                  ))}
                </ul>
              </Block>
            )}
          </Grid>
        </Section>
      </Infos>
      <Social data={data.Association} />
      <SubEntityList associationId={id} data={data} domain={domain} location={location} />
      <Grey>
        <Section>
          <h1>Contactez-nous</h1>
          <Form type="CONTACT_ASSOCIATION" association={id} />
        </Section>
      </Grey>
      <div>
        <Center>
          <p>
            Ces informations vous paraissent incomplètes ou pas à jour ? Vous disposez d’informations complémentaires ?
            <br />
            Faites-en profiter vos voisins !
          </p>
          {open ? (
            <Form type="UPDATE_ASSOCIATION_INFORMATION" association={id} />
          ) : (
            <Button $size="small" onClick={toggleOpen}>
              Dites-le nous
            </Button>
          )}
        </Center>
      </div>
      <Grey>
        <Section>
          <h1>Associations proposant des activités similaires</h1>
          {related.length < 1 ? (
            <p>Aucune association similaire</p>
          ) : (
            <Grid>
              {shuffleArray(related)
                .splice(0, 3)
                .map((asso) => (
                  <Block key={asso.id} $col={3}>
                    <AssociationCard association={asso} />
                  </Block>
                ))}
            </Grid>
          )}
        </Section>
      </Grey>
    </Layout>
  )
}

const SubEntityList = ({
  associationId,
  data,
  location,
  domain,
}: {
  associationId: string
  data: {
    Association: Association
    allAssociations: Association[]
  }
  domain: Domain
  location: PageProps["location"]
}) => {
  const areMissionsVisible = Boolean(domain.mission_visible_on_frontend)
  const [params, filterType] = getParams(location, [
    "tags",
    "ages",
    "days",
    "schedules",
    "activities",
    "missions",
    "mission_causes",
    "mission_skills",
    "mission_recurences",
  ])
  const exact = getParam(location, "exact")

  const variables: Record<string, any> = {
    ...params,
    association: associationId,
    domainId: DOMAIN_ID,
  }
  if (exact) {
    variables.exact = exact === "1"
  }
  const [hasSearch, setHasSearch] = useState(Object.keys(params).length > 0)
  const search = useClientQuery<SearchResults>(SEARCH_QUERY, hasSearch ? variables : null)

  const searchType = useRef<AssociationFilterType>(hasSearch ? filterType || AssociationFilterType.Activity : undefined)
    .current

  const discardSearchFilter = () => setHasSearch(false)

  const initialSelectedSubEntity =
    filterType ||
    (areMissionsVisible &&
      data.Association.activities.length === 0 &&
      data.Association.missions.length > 0 &&
      AssociationFilterType.Mission) || // Show missions directly if there are no activities
    AssociationFilterType.Activity

  const [selectedSubEntity, setSelectedSubEntity] = useState<AssociationFilterType>(initialSelectedSubEntity)
  // eslint-disable-next-line no-console
  const toggleSelectedSubEntity = useCallback(
    (entity: AssociationFilterType) => {
      if (entity === selectedSubEntity) {
        return // Already selected
      }
      // If we move to a different entity, the search filter doesn't make sense anymore, so we discard it.
      discardSearchFilter()
      setSelectedSubEntity(entity)
      window.history.pushState(
        {},
        null,
        entity === AssociationFilterType.Mission ? `${location.pathname}?type=${entity}` : location.pathname
      )
    },
    [location.pathname, selectedSubEntity]
  )

  const initialSelectedSubEntityRef = useMutatingRef(initialSelectedSubEntity)
  useEffect(() => {
    // By default, Gatsby prerenders the page as `pathname` without parameters, which should show the activity list.
    // If we have missions parameters, we need to switch to the mission list as soon as possible.
    setSelectedSubEntity(initialSelectedSubEntityRef.current)
  }, [initialSelectedSubEntityRef])

  if (hasSearch && !search.data) {
    // For some reason, Gatsby freezes the className of the rendered components.
    // To avoid that, we dont render the final components before search.data is loaded.
    // In the meantime we simply show a Loader.
    // see https://github.com/gatsbyjs/gatsby/issues/19751
    return <Loader $background={COLORS.bright} />
  }

  return (
    <White>
      <Section>
        <h1>
          <SubEntityTabTitle
            onClick={() => toggleSelectedSubEntity(AssociationFilterType.Activity)}
            className={selectedSubEntity === AssociationFilterType.Activity && "selected"}
          >
            {hasSearch && searchType === AssociationFilterType.Activity
              ? "Activités répondant à mes critères"
              : "Activités"}
          </SubEntityTabTitle>
          {areMissionsVisible && (
            <SubEntityTabTitle
              onClick={() => toggleSelectedSubEntity(AssociationFilterType.Mission)}
              className={selectedSubEntity === AssociationFilterType.Mission && "selected"}
            >
              {hasSearch && searchType === AssociationFilterType.Mission
                ? "Missions répondant à mes critères"
                : "Missions"}
            </SubEntityTabTitle>
          )}
        </h1>
        {selectedSubEntity === AssociationFilterType.Activity && (
          <ActivityList hasSearch={hasSearch} search={search} data={data} handleMore={discardSearchFilter} />
        )}
        {areMissionsVisible && selectedSubEntity === AssociationFilterType.Mission && (
          <MissionList hasSearch={hasSearch} search={search} data={data} handleMore={discardSearchFilter} />
        )}
      </Section>
    </White>
  )
}

const ActivityList = ({
  hasSearch,
  search,
  data,
  handleMore,
}: {
  hasSearch: boolean
  search: {
    loading: boolean
    data: SearchResults
  }
  data: {
    Association: Association
    allAssociations: Association[]
  }
  handleMore: () => void
}) => {
  const activities =
    (hasSearch && search.data?.allActivities) || data.Association.activities.slice().sort(sortActivities)
  return (
    <>
      {hasSearch && search.loading ? (
        <Loader $background={COLORS.bright} />
      ) : activities.length < 1 ? (
        <p>Aucune activité {hasSearch ? "répondant à mes critères" : ""} référencée pour cette association</p>
      ) : (
        <>
          <Grid>
            {activities.map((activity) => (
              <Block key={activity.id} $col={3}>
                <ActivityCard activity={activity} />
              </Block>
            ))}
          </Grid>
        </>
      )}
      {hasSearch && !search.loading && (
        <MoreButton onClick={handleMore} $size="small">
          Toutes les activités
        </MoreButton>
      )}
    </>
  )
}

const MissionList = ({
  hasSearch,
  search,
  data,
  handleMore,
}: {
  hasSearch: boolean
  search: {
    loading: boolean
    data: SearchResults
  }
  data: {
    Association: Association
    allAssociations: Association[]
  }
  handleMore: () => void
}) => {
  const missions = (hasSearch && search.data?.allMissions) || data.Association.missions.slice().sort(sortMissions)
  return (
    <>
      {hasSearch && search.loading ? (
        <Loader $background={COLORS.bright} />
      ) : missions.length < 1 ? (
        <p>Aucune mission {hasSearch ? "répondant à mes critères" : ""} référencée pour cette association</p>
      ) : (
        <>
          <Grid>
            {missions.map((mission) => (
              <Block key={mission.id} $col={3}>
                <MissionCard mission={mission} />
              </Block>
            ))}
          </Grid>
        </>
      )}
      {hasSearch && !search.loading && (
        <MoreButton onClick={handleMore} $size="small">
          Toutes les missions
        </MoreButton>
      )}
    </>
  )
}

export default AssociationTemplate

export const query = graphql`
  query($id: ID!, $tags: [ID!]) {
    assolib {
      Association(id: $id) {
        ...AssociationFields
        activities {
          ...ActivityFields
        }
        missions {
          ...MissionFields
        }
      }
      allAssociations(tags: $tags) {
        ...SearchAssociationFields
      }
    }
  }
`
